import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CLocalBtnList,
  CSectTitle03,
  CFaqList,
  CBreadCrumb,
  LFaqContact,
  CAnchorNav,
} from '../../../components/_index';
import '../../../assets/_sass/page/faq.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'よくあるご質問',
            sub: 'FAQ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/faq/kv.png',
              },
              imgSp: {
                src: '/assets/images/faq/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>

      <div className="p_faq">
        <section className="u_mtMedium">
          <LWrap>
            <CLocalBtnList
              data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: '/faq/',
                  },
                },
                {
                  label: 'レストラン＆バー',
                  link: {
                    href: '/faq/restaurants/',
                  },
                },
                {
                  label: 'ウエディング',
                  link: {
                    href: '/faq/wedding/',
                  },
                },
                {
                  label: '宴会・会議',
                  link: {
                    href: '/faq/banquet/',
                  },
                },
                {
                  label: 'お子様連れのお客様',
                  link: {
                    href: '/faq/child/',
                  },
                },
                {
                  label: 'バリアフリー',
                  link: {
                    href: '/faq/accessibility/',
                  },
                },
              ]}
            />
          </LWrap>
          {/* <LWrap size="small">
            <CAnchorNav
              exClass="u_mt50"
              data={[
                {
                  title: <>宿泊</>,
                  link: {
                    href: '#stay',
                  },
                },
                {
                  title: <>レストラン</>,
                  link: {
                    href: '#restaurants',
                  },
                },
                {
                  title: <>設備・サービス</>,
                  link: {
                    href: '#facility',
                  },
                },
                {
                  title: <>アクセス</>,
                  link: {
                    href: '#access',
                  },
                },
                {
                  title: <>その他</>,
                  link: {
                    href: '#other',
                  },
                },
              ]}
            />
          </LWrap> */}
        </section>

        <section className="u_pt80" id="stay">
          <LWrap>
            <CSectTitle03 title="宿泊" />
            <CFaqList
              data={[
                {
                  q: '子供が一緒の場合、追加料金はかかりますか?',
                  a: (
                    <>
                      小学生以下のお子様は、添い寝の場合に限り無料です。詳細は
                      <a href="/stay/child/">こちら</a>をご確認ください。
                      <br />
                      エグゼクティブラウンジのご利用（スイート、エグゼクティブフロアご宿泊者のみ）は、17:00以降小学生以下のお子様のご入場をご遠慮いただいております。
                    </>
                  ),
                },
                {
                  q: '朝食付プランで申し込んだ場合、添い寝の子供の朝食はどうなりますか?',
                  a: (
                    <>
                      別途ご料金を頂戴しております。お子様連れのお客様へのご案内は
                      <a href="/stay/child/">こちら</a>をご確認ください。
                    </>
                  ),
                },
                {
                  q: '子供向けの備品やサービスはありますか?',
                  a: (
                    <>
                      ベビーベッド、アメニティ、貸出備品のご用意がございます。
                      <br />
                      お子様連れのお客様へのご案内は
                      <a href="/stay/child/">こちら</a>をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'エグゼクティブラウンジは子供も利用できますか?',
                  a: (
                    <>
                      朝食、ティータイムは、添い寝のお子様は無料でご利用いただけます。
                      <br />
                      17:00以降のカクテルタイムにつきましては、添い寝の有無にかかわらず小学生以下のお子様のご入場をご遠慮いただいております。
                    </>
                  ),
                },
                {
                  q: 'ミルクの温めや哺乳瓶の消毒はできますか?',
                  a: (
                    <>
                      ・ミルク用のお湯：お部屋に備え付けの電気ケトルをご利用いただけます。
                      <br />
                      ・哺乳瓶の消毒や、離乳食などの温め：1Fに電子レンジコーナーをご用意しております。
                      <br />
                      もしくはルームサービスでも、お預かりさせていただきご対応を承ります。哺乳瓶の消毒には、電子レンジ対応の消毒キットをご持参ください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="restaurants">
          <LWrap>
            <CSectTitle03 title="レストラン" />
            <CFaqList
              data={[
                {
                  q: '食物アレルギーがあるのですが、どのような対応していただけますか?',
                  a: (
                    <>
                      皆様にお食事をお楽しみいただけるよう、できる限りの対応をいたしております。
                      <br />
                      食物アレルギーをお持ちの方へのご案内は
                      <a href="/restaurants/allergy/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: '水天宮にお宮参りに行くのですが、何かプランはありますか?',
                  a: (
                    <>
                      お食事、写真撮影付の「お宮参りプラン」をはじめ、お子様の成長に合わせて各種お祝いプランをご用意しております。
                      <br />
                      詳細は<a href="/oiwai/">こちら</a>をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'お子様メニューはありますか?',
                  a: (
                    <>
                      <a href="/restaurants/plan/eqv1t39svswf/">
                        鉄板焼 すみだ
                      </a>
                      、
                      <a href="/restaurants/plan/0vpd2n5r8i/">
                        日本料理 源氏香
                      </a>
                      、
                      <a href="/restaurants/plan/2rxfb57bcn/">
                        中国料理 桂花苑
                      </a>
                      、
                      <a href="/restaurants/plan/q6lhag7gpl/">
                        シェフズダイニング シンフォニー
                      </a>
                      にてご用意しております。
                    </>
                  ),
                },
                {
                  q: 'ベビーカーのまま利用できるレストランはありますか?',
                  a: (
                    <>
                      B1F メインバー
                      ロイヤルスコッツ以外の全レストランにてご利用いただけます。
                    </>
                  ),
                },
                {
                  q: '年齢制限があるレストランはありますか?',
                  a: (
                    <>
                      B1F メインバー
                      ロイヤルスコッツでは、20歳未満の方のご入場はご遠慮いただいております。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="facility">
          <LWrap>
            <CSectTitle03 title="設備・サービス" />
            <CFaqList
              data={[
                {
                  q: 'おむつ替えのできる場所はありますか?',
                  a: (
                    <>
                      下記をご利用いただけます。
                      <br />
                      ベビーベッド：1F ロビー
                      フロントカウンター横（授乳室併設、ご利用の際はスタッフにお申し付けください）
                      <br />
                      折りたたみシート：1F バリアフリートイレ内
                      <br />
                      おむつ替えベッド：B1F、2F、4F 女性用化粧室内
                    </>
                  ),
                },
                {
                  q: '授乳室はありますか?',
                  a: (
                    <>
                      1F ロビー
                      フロントカウンター横にご用意しております（ご利用の際はスタッフにお申し付けください）。
                    </>
                  ),
                },
                {
                  q: 'ホテル内にコンビニはありますか?',
                  a: <>ホテル内にはございませんが、近隣に数店舗ございます。</>,
                },
                {
                  q: '記念写真を撮影してくれるところはありますか?',
                  a: (
                    <>
                      4F 写真室
                      リトル・マーサにて、ご集合写真などの撮影を承ります。館内施設のご案内は
                      <a href="/about/facility/shop/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: '子供と利用する時に便利な設備やサービスはありますか?',
                  a: (
                    <>
                      レストランでは、お子様椅子・クーハンを無料でご用意しております。
                      <br />
                      そのほか、下記をご利用いただけます。
                      <br />
                      授乳室、ベビーベッド：1F ロビー
                      フロントカウンター横（ご利用の際はスタッフにお申し付けください）
                      <br />
                      折りたたみシート：1F バリアフリートイレ内
                      <br />
                      おむつ替えベッド：B1F、2F、4F 女性用化粧室内
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="access">
          <LWrap>
            <CSectTitle03 title="アクセス" />
            <CFaqList
              data={[
                {
                  q: 'ホテルへの行き方を教えてください。',
                  a: (
                    <>
                      ホテルへのアクセスは<a href="/about/access/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'ホテルから東京シティエアターミナルへの行き方、バスの運行時間、所要時間、料金など教えてください。',
                  a: (
                    <>
                      正面玄関を出て左手の隣接している建物が東京シティエアターミナルです。ホテル2Fの専用通路もご利用いただけます。
                      <br />
                      羽田空港までの所要時間は約25分、約30分間隔で運行、成田空港までが約55分、約10分から15分間隔で運行しております。
                      <br />
                      東京シティエアターミナルの詳細は公式サイトをご確認ください。
                      <br />
                      東京シティ・エアターミナル株式会社
                      東京シティエアターミナル：
                      <a
                        href="https://www.tcat-hakozaki.co.jp/"
                        target="_blank"
                      >
                        https://www.tcat-hakozaki.co.jp/
                      </a>
                    </>
                  ),
                },
                {
                  q: '東京駅からの送迎バスはありますか?有料ですか?',
                  a: (
                    <>
                      地下鉄、タクシーなど公共交通機関をご利用ください。
                      <br />
                      また、無料巡回バスもご利用いただけます。詳細は
                      <a href="/about/tourism/">こちら</a>をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'リムジンバスの予約はできますか?',
                  a: (
                    <>
                      リムジンバスの公式サイトにて成田空港・羽田空港ともにご予約可能です。
                      <br />
                      東京空港交通株式会社 リムジンバス予約Web：
                      <a
                        href="https://www.limousinebus.co.jp/guide/reserve/"
                        target="_blank"
                      >
                        https://www.limousinebus.co.jp/guide/reserve/
                      </a>
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="other">
          <LWrap>
            <CSectTitle03 title="その他" />
            <CFaqList
              data={[
                {
                  q: 'バスツアーの予約をお願いできますか',
                  a: (
                    <>
                      1F ロビー
                      ベルデスクにて承ります。詳細はホテルへお問合せください。
                    </>
                  ),
                },
                {
                  q: '最寄りのレンタカーショップはありますか?',
                  a: (
                    <>
                      東京シティエアターミナル B1F
                      にございます。詳細は公式サイトをご確認ください。
                      <br />
                      東京シティ・エアターミナル株式会社
                      東京シティエアターミナル：
                      <a
                        href="https://www.tcat-hakozaki.co.jp/"
                        target="_blank"
                      >
                        https://www.tcat-hakozaki.co.jp/
                      </a>
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
      </div>
      <LFaqContact />
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
